var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", [
        _vm._v("Forms required for the drivers arrival and departure.."),
      ]),
      _c(
        "div",
        { staticClass: "disclaimer" },
        [
          _vm._v(" Customize your forms at the warehouse "),
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "warehouses.details.forms",
                  params: { warehouseId: _vm.appointment.dock.warehouseId },
                },
              },
            },
            [_vm._v(" Fields and Forms ")]
          ),
          _vm._v(" section. "),
        ],
        1
      ),
      _c("custom-forms-data-panels", {
        staticClass: "mt-4",
        attrs: {
          editable: false,
          "object-ids": {
            appointmentId: _vm.appointment.id,
            assetvisitId: _vm.appointment.assetVisit?.id,
          },
          appointment: _vm.appointment,
          triggers: _vm.staticTriggers,
          warehouse: _vm.appointment.warehouse,
          timezone: _vm.appointment.warehouse.timezone,
          "military-time-enabled": _vm.$isMilitaryTimeEnabled(
            _vm.appointment.warehouse
          ),
        },
        scopedSlots: _vm._u([
          {
            key: "additional-disclaimer",
            fn: function ({ trigger, lastChangedDateTimesInTimezone }) {
              return [
                !lastChangedDateTimesInTimezone[trigger?.id]
                  ? _c("span", { staticClass: "text--color-text-disabled" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.novaCore.CustomFormsFeaturesData[
                              trigger?.feature
                            ]?.title?.split(":")[0]
                          ) +
                          " pending "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("custom-forms-data-panels", {
        attrs: {
          editable: true,
          "object-ids": {
            appointmentId: _vm.appointment.id,
            assetvisitId: _vm.appointment.assetVisit?.id,
          },
          appointment: _vm.appointment,
          triggers: _vm.editableTriggers,
          warehouse: _vm.appointment.warehouse,
          timezone: _vm.appointment.warehouse.timezone,
          "military-time-enabled": _vm.$isMilitaryTimeEnabled(
            _vm.appointment.warehouse
          ),
        },
        scopedSlots: _vm._u([
          {
            key: "additional-disclaimer",
            fn: function ({ trigger, lastChangedDateTimesInTimezone }) {
              return [
                !lastChangedDateTimesInTimezone[trigger?.id]
                  ? _c("span", { staticClass: "text--color-text-disabled" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.novaCore.CustomFormsFeaturesData[
                              trigger?.feature
                            ]?.title?.split(":")[0]
                          ) +
                          " pending "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }