import { ref, computed, onMounted, watch, onBeforeUnmount, getCurrentInstance } from 'vue';
import axios from 'axios';
import {
  useAppointmentHelpers,
  useEventHub,
  useMixpanel,
  useNovaCore,
  useRouter,
  useSockets,
  useStore
} from '@/composables/index';

export const appointmentDetailsHelpersProps = {
  appointment: {
    type: Object,
    required: true
  },
  shouldEditStatus: {
    type: Boolean,
    required: false,
    default: false
  },
  shouldDisplayCancelButton: {
    type: Boolean,
    required: false,
    default: true
  },
  appointmentWarehouse: {
    type: Object,
    required: true
  },
  readOnly: {
    type: Boolean,
    required: false
  }
};

export default function useAppointmentDetailsHelpers(
  appointment = {},
  appointmentWarehouse = {},
  readOnly = false
) {
  const vueInstance = getCurrentInstance().proxy;
  const sockets = useSockets();
  const router = useRouter();
  const eventHub = useEventHub();
  const store = useStore();
  const rolePermissions = store.state.Auth.rolePermissions;
  const novaCore = useNovaCore();
  const mixpanel = useMixpanel();
  const showRescheduleDialog = ref(false);
  const loading = ref(true);
  const tags = ref(appointment.tags);
  const lockTagEditing = ref(false);
  const warehouseTriggers = ref([]);
  const assetVisitFormData = ref([]);
  const appointmentHelpers = useAppointmentHelpers(appointment);
  const appointmentTableRows = ref({
    dockName: {
      label: 'Dock Name',
      value: `${appointment.readableDockName ?? appointment.dock?.name ?? 'Dock'}${
        appointment.dock.isActive === false ? ' (Inactive)' : ''
      }`,
      tdClass: 'pb-4',
      isHidden: false
    },
    loadType: {
      label: 'Load Type',
      value: '',
      tdClass: 'pb-4',
      isHidden: false
    },
    refNumber: {
      label: 'Reference Number',
      value: appointment.refNumber || '----',
      tdClass: 'pb-4',
      isHidden: false
    }
  });

  const canReschedule = computed(() => {
    return (
      rolePermissions.canUpdateAppointment &&
      novaCore.isRescheduleAllowed(appointment.status) &&
      !readOnly
    );
  });

  const reschedulesCount = computed(() => {
    return novaCore.getReschedulesCount(appointment);
  });

  const reschedulesString = computed(() => {
    return novaCore.getReschedulesString(reschedulesCount.value);
  });

  const gateManagementTriggers = computed(() => {
    return (
      warehouseTriggers.value?.filter(
        t =>
          (novaCore.CustomFormsGateManagementFeatures.includes(t.feature) &&
            t.dataEntityName === novaCore.CustomFormDataEntitiesEnum.appointment) ||
          t.dataEntityName === novaCore.CustomFormDataEntitiesEnum.assetVisit
      ) || []
    );
  });

  const claimSupportTriggers = computed(() => {
    return (
      warehouseTriggers.value?.filter(t => novaCore.CustomFormsClaimFeatures.includes(t.feature)) ||
      []
    );
  });

  const featureData = feature => {
    return novaCore.CustomFormsFeaturesData[feature] || {};
  };

  const trackApptDetailsMixpanelEvent = () => {
    const entryPoint = mixpanel.getEntryPoint(vueInstance, [
      {
        entryPoint: 'Carrier Insights',
        component: 'carrier-metrics-table'
      },
      {
        entryPoint: 'Mobile List View',
        component: 'appointments-list-mobile'
      },
      {
        entryPoint: 'Appointments List',
        component: 'appointments-list'
      },
      {
        entryPoint: 'Self Check-in List',
        component: 'check-in-notification-panel'
      },
      {
        entryPoint: 'Appointments Grid',
        component: 'appointments-page'
      },
      {
        entryPoint: 'Search Appointments',
        component: 'appointments-search-results'
      }
    ]);
    mixpanel.track(mixpanel.events.MODULE.APPOINTMENT.APPOINTMENT_DETAILS, {
      'Org Name': store.state.App.org?.name,
      'Org ID': store.state.App.org?.id,
      'Warehouse Name': appointmentWarehouse.name,
      'Warehouse ID': appointmentWarehouse.id,
      'Appointment ID': appointment.id,
      'Entry Point': entryPoint
    });
  };

  const initializeRowValues = () => {
    const refNumSettings = store.getters['Settings/refNumSettings'](appointmentWarehouse);
    appointmentTableRows.value.loadType.value = store.getters['LoadTypes/loadTypeName'](
      appointment.loadType
    );
    appointmentTableRows.value.refNumber.label = refNumSettings.displayName;
    appointmentTableRows.value.refNumber.isHidden = !refNumSettings.isVisible;
    appointmentTableRows.value.refNumber.value = appointment.refNumber;
    appointmentTableRows.value.dockName.value = appointment.dock.name;
  };

  const updateAppointmentTags = async () => {
    lockTagEditing.value = true;
    await axios
      .patch(`appointment/${appointment.id}`, {
        tags: tags.value
      })
      .then(() => {
        store.dispatch('Appointments/trackMixpanelEvent', {
          appointment: appointment || {},
          change: 'Tags'
        });
      })
      .finally(() => (lockTagEditing.value = false));
  };

  const setWarehouseTriggers = async () => {
    const response = await axios.get('custom-forms/trigger', {
      params: {
        s: {
          objectId: appointmentWarehouse.id,
          entityName: 'warehouse'
        },
        limit: 100
      }
    });
    warehouseTriggers.value = response?.data?.data;
  };

  const getAssetVisitFormData = async () => {
    if (appointment.assetVisit) {
      try {
        const response = await axios.get('custom-forms/form-data', {
          params: {
            s: { objectId: appointment.assetVisit.id },
            limit: 1000
          }
        });
        return response?.data?.data || [];
      } catch (_) {
        return [];
      }
    }
  };

  onMounted(async () => {
    initializeRowValues();
    appointmentHelpers.setCustomFieldsRows();
    const triggers = store.state.Calendar.selectedWarehouseTriggers;
    if (triggers) {
      warehouseTriggers.value = triggers;
    } else {
      await setWarehouseTriggers();
    }
    assetVisitFormData.value = await getAssetVisitFormData();
    loading.value = false;
    eventHub.$on(`update-Trigger`, setWarehouseTriggers);
    eventHub.$on(`create-FormField`, setWarehouseTriggers);
  });

  onBeforeUnmount(() => {
    router.push({ query: {} });
    Object.keys(sockets.actions).map(action => {
      eventHub.$off(`${action}-Trigger`, setWarehouseTriggers);
      eventHub.$off(`${action}-FormField`, setWarehouseTriggers);
    });
  });

  watch(tags, async newTags => {
    if (JSON.stringify(newTags) !== JSON.stringify(appointment.tags)) {
      await updateAppointmentTags();
    }
  });

  watch(
    () => appointment,
    newAppointment => {
      initializeRowValues();
      appointmentHelpers.setCustomFieldsRows();
      tags.value = newAppointment.tags;
    }
  );

  return {
    showRescheduleDialog,
    loading,
    tags,
    lockTagEditing,
    warehouseTriggers,
    assetVisitFormData,
    appointmentTableRows,
    canReschedule,
    reschedulesCount,
    reschedulesString,
    gateManagementTriggers,
    claimSupportTriggers,
    featureData,
    trackApptDetailsMixpanelEvent,
    initializeRowValues,
    updateAppointmentTags,
    setWarehouseTriggers,
    getAssetVisitFormData
  };
}
