// File: `useAppointmentMixinBase.js`
import { ref, computed, onMounted, watch } from 'vue';
import { isNumber } from 'class-validator';
import { DateTime } from 'luxon';
import { useMixpanel, useNotify, useNovaCore, useStore } from '@/composables/index';
import util from '@satellite/plugins/util';
import { useRoute } from 'vue-router/composables';

export const appointmentHelpersProps = {
  appointment: {
    type: Object,
    required: true
  }
};

export default function useAppointmentHelpers(appointment) {
  const showConfirmDialog = ref(false);
  const showDetailsDialog = ref(false);
  const customFields = ref({});
  const store = useStore();
  const novaCore = useNovaCore();
  const notify = useNotify();
  const mixpanel = useMixpanel();
  const route = useRoute();

  const cancelAppointment = ref(null);

  const timezone = computed(() => appointment?.dock?.warehouse?.timezone ?? null);

  const useMilitaryTime = computed(() => {
    return store.getters['Settings/isMilitaryTimeEnabled'](appointment?.dock?.warehouse);
  });

  const canCancel = computed(() => {
    let canCancel = false;
    if (appointment?.status) {
      canCancel = novaCore.isCancelAllowed(appointment.status);
    }
    return canCancel;
  });

  const isCancelled = computed(() => {
    return isStatusMatch(novaCore.AppointmentStatus.Cancelled);
  });

  const isRequested = computed(() => {
    return isStatusMatch(novaCore.AppointmentStatus.Requested);
  });

  const isCompleted = computed(() => {
    return isStatusMatch(novaCore.AppointmentStatus.Completed);
  });

  const isScheduled = computed(() => {
    return isStatusMatch(novaCore.AppointmentStatus.Scheduled);
  });

  const isNoShow = computed(() => {
    return isStatusMatch(novaCore.AppointmentStatus.NoShow);
  });

  const cancelButtonIcon = computed(() => {
    return isCancelled.value ? 'mdi-alert' : 'mdi-delete-outline';
  });

  const cancelAppointmentLabel = computed(() => {
    let label = 'Cancel Appointment';
    if (appointment?.statusTimeline) {
      let cancelledTime = appointment.statusTimeline[novaCore.AppointmentStatus.Cancelled];
      if (cancelledTime) {
        const cancelledDateTime = novaCore.formatDateTimeWithMilitarySupport(
          cancelledTime,
          timezone.value,
          novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTimeAMPMCompact,
          useMilitaryTime.value,
          novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTime24
        );
        label = `This appointment was cancelled on ${cancelledDateTime}`;
      }
    }
    return label;
  });

  const isPastAppointment = computed(() => {
    const now = DateTime.now().setZone(timezone.value);
    const appointmentStartDateTime = DateTime.fromISO(appointment.start).setZone(timezone.value);
    return appointmentStartDateTime < now;
  });

  const user = computed(() => {
    return appointment.user;
  });

  const company = computed(() => {
    return user.value.company;
  });

  const companyName = computed(() => {
    return company.value?.name;
  });

  const scac = computed(() => {
    return appointment.user.company?.scac || '----';
  });

  const formattedAppointmentDate = computed(() => {
    return novaCore.formatDateTimeWithMilitarySupport(
      appointment.start,
      timezone.value,
      novaCore.LuxonDateTimeFormats.LongDateShortMonth,
      useMilitaryTime.value,
      novaCore.LuxonDateTimeFormats.LongDateShortMonth
    );
  });

  const formattedAppointmentDateTime = computed(() => {
    const interval = getAppointmentInterval(appointment);
    return `${formattedAppointmentDate.value} • ${interval}`;
  });

  const appointmentDateTimeHeader = computed(() => {
    const interval = getAppointmentInterval(appointment);
    return `${formattedAppointmentDate.value}<span class='mx-2 d-inline-block'>&bull;</span>${interval}`;
  });

  const refNumLabel = computed(() => {
    return store.getters['Settings/settingValue'](
      'referenceNumberDisplayName',
      appointment?.dock?.warehouse?.settings || null
    );
  });

  const currentTimestamp = computed(() => {
    return novaCore.formatDateTimeWithMilitarySupport(
      DateTime.now().toISO(),
      timezone.value,
      novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTimeAMPM,
      useMilitaryTime.value,
      novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTime24
    );
  });

  const warehouse = computed(() => {
    return appointment.dock?.warehouse;
  });

  const warehouseAddress = computed(() => {
    return `${warehouse.value.street}, ${warehouse.value.city}, ${warehouse.value.state} ${warehouse.value.zip}`;
  });

  const appointmentNotes = computed(() => {
    return appointment.notes?.length > 0 ? appointment.notes : 'N/A';
  });

  const appointmentTags = computed(() => {
    return appointment.tags?.length > 0 ? appointment.tags.join(', ') : 'N/A';
  });

  const dock = computed(() => {
    return appointment.dock;
  });

  const loadType = computed(() => {
    return appointment.loadType;
  });

  const statusTimeline = computed(() => {
    return appointment.statusTimeline;
  });

  const arrivalTime = computed(() => {
    return statusTimeline.value[novaCore.AppointmentStatus.Arrived];
  });

  const formattedArrivalTime = computed(() => {
    return arrivalTime.value
      ? novaCore.formatDateTimeWithMilitarySupport(
          arrivalTime.value,
          timezone.value,
          novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTimeAMPM,
          useMilitaryTime.value,
          novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTime24
        )
      : 'Waiting for carrier to arrive';
  });

  const completedTime = computed(() => {
    return statusTimeline.value[novaCore.AppointmentStatus.Completed];
  });

  const formattedCompletedTime = computed(() => {
    return completedTime.value
      ? novaCore.formatDateTimeWithMilitarySupport(
          completedTime.value,
          timezone.value,
          novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTimeAMPM,
          useMilitaryTime.value,
          novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTime24
        )
      : 'Not completed yet';
  });

  const noShow = computed(() => {
    return appointment.status === novaCore.AppointmentStatus.NoShow;
  });

  const arrivalTimeDiff = computed(() => {
    let readableDuration = '';
    if (arrivalTime.value) {
      readableDuration = util.getReadableDuration(
        appointment.start,
        arrivalTime.value,
        timezone.value,
        ' late'
      );
    }
    return readableDuration;
  });

  const completedTimeDiff = computed(() => {
    let readableDuration = '';
    if (completedTime.value) {
      readableDuration = util.getReadableDuration(
        arrivalTime.value,
        completedTime.value,
        timezone.value,
        ' dwell'
      );
    }
    return readableDuration;
  });

  const canCopy = computed(() => {
    return Boolean(navigator?.clipboard);
  });

  const isStatusMatch = status => {
    return appointment?.status === status;
  };

  const formatDateTime = (dateTime, luxonDateTimeFormat, applyTimezone = false) => {
    if (!applyTimezone) {
      return novaCore.getFormattedTime(dateTime, format);
    }
    return DateTime.fromISO(dateTime)
      .setZone(timezone.value)
      .toFormat(luxonDateTimeFormats)
      .toUpperCase();
  };

  const getAbbreviatedTimezoneName = appointment => {
    return novaCore.formatDateTimeWithMilitarySupport(
      appointment.start,
      timezone.value,
      novaCore.LuxonDateTimeFormats.AbbreviatedNamedOffset,
      false,
      novaCore.LuxonDateTimeFormats.AbbreviatedNamedOffset
    );
  };

  const getAppointmentInterval = (appointment, applyTimezone = true) => {
    const tz = applyTimezone ? timezone.value : null;

    const start = novaCore.formatDateTimeWithMilitarySupport(
      appointment.start,
      tz,
      novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
      useMilitaryTime.value,
      novaCore.LuxonDateTimeFormats.Extended24HrTime
    );
    const end = novaCore.formatDateTimeWithMilitarySupport(
      appointment.end,
      tz,
      novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
      useMilitaryTime.value,
      novaCore.LuxonDateTimeFormats.Extended24HrTime
    );

    let intervalString = `${start} - ${end}`;
    if (applyTimezone) {
      const timezone = getAbbreviatedTimezoneName(appointment);
      intervalString += ` (${timezone})`;
    }
    return novaCore.transformForUserFriendlyTimezone(intervalString, tz);
  };

  const confirmCancel = async () => {
    showConfirmDialog.value = true;
    await cancelAppointment.value.open();
  };

  const handleApptIdURLQuery = async () => {
    const routeAppointmentId = route.query.appointmentId;
    if (routeAppointmentId && routeAppointmentId !== store.getters['Calendar/selectedEvent']?.id) {
      await fetchAndOpenAppt(routeAppointmentId);
    }
  };

  const fetchAndOpenAppt = async apptId => {
    const response = await store.dispatch('Calendar/getEvent', apptId);
    store.commit('Calendar/setSelectedEvent', response);
    showDetailsDialog.value = true;
  };

  const getCustomFieldValue = customField => {
    const shouldGetValue =
      Boolean(customField.value) || customField?.value === false || isNumber(customField.value);
    if (shouldGetValue) {
      return novaCore.getCustomFieldFormattedValue(customField, {
        [novaCore.CustomFieldType.Document]: { generateLink: true },
        [novaCore.CustomFieldType.MultiDocument]: { generateLink: true },
        [novaCore.CustomFieldType.Timestamp]: {
          timezone: timezone.value,
          formatAsMilitary: useMilitaryTime.value
        }
      });
    }
  };

  const copyApptId = () => {
    if (canCopy.value) {
      navigator.clipboard.writeText(appointment.id);
      notify('Copied to Clipboard!');
      mixpanel.track(mixpanel.events.ACTION.COPIED_APPOINTMENT_ID, {
        'Org Name': store.state.App.org?.name,
        'Org ID': store.state.App.org?.id,
        'Appointment ID': appointment.id
      });
    }
  };

  // TODO: This is also above as a computed property - figure out which one makes most sense
  // This takes a warehouse, the other computes the address based on the appointment's warehouse
  const getWarehouseAddress = warehouse => {
    return `${warehouse.street}, ${warehouse.city}, ${warehouse.state} ${warehouse.zip}`;
  };

  const getUserName = user => {
    return `${user.firstName} ${user.lastName}`;
  };

  const setCustomFieldsRows = () => {
    customFields.value = {};
    if (Array.isArray(appointment.customFields)) {
      appointment.customFields.map((field, index) => {
        const fieldName = novaCore.getDefaultCustomFieldName(field);
        const fieldTemplate = appointment.dock.warehouse.customApptFieldsTemplate?.find(
          template => fieldName === novaCore.getDefaultCustomFieldName(template)
        );

        if (field.value || field.value === false || isNumber(field.value)) {
          customFields.value[fieldName] = {
            label: field.label,
            value: getCustomFieldValue(field),
            name: fieldName,
            tdClass: 'pb-4',
            key: `custom-field-${index}`,
            hiddenFromCarrier: Boolean(fieldTemplate?.hiddenFromCarrier),
            requiredForCarrier: Boolean(fieldTemplate?.requiredForCarrier),
            requiredForWarehouse: Boolean(fieldTemplate?.requiredForWarehouse),
            type: field.type
          };
        }
      });
    }
  };

  const formatPhoneNumber = value => {
    return novaCore.formatPhoneNumber(value);
  };

  const showCustomField = customField => {
    if (!customField) {
      return false;
    }

    const isDocument = [
      novaCore.CustomFieldType.Document,
      novaCore.CustomFieldType.MultiDocument
    ].includes(customField.type);

    if (isDocument) {
      return false;
    }

    return Boolean(customField.value) || customField.value === false || isNumber(customField.value);
  };

  const getRowVal = val => {
    if (val === '' || val === undefined || val === null) {
      return '<span>----</span>';
    }
    return val;
  };

  const getAppointmentDateTimeHeader = appointment => {
    if (appointment?.id) {
      const interval = getAppointmentInterval(appointment, false);
      return `${formattedAppointmentDate.value}<span class='mx-2 d-inline-block'>&bull;</span>${interval}`;
    }
  };

  const getFullAppointmentTimezone = appointment => {
    if (appointment?.id) {
      return DateTime.fromISO(appointment.start).setZone(timezone.value).zoneName.replace('_', ' ');
    }
  };

  onMounted(() => {
    handleApptIdURLQuery();
  });

  watch(
    () => appointment,
    () => {
      setCustomFieldsRows();
    }
  );

  return {
    showConfirmDialog,
    showDetailsDialog,
    customFields,
    useMilitaryTime,
    canCancel,
    isCancelled,
    isRequested,
    isCompleted,
    isScheduled,
    isNoShow,
    cancelButtonIcon,
    cancelAppointmentLabel,
    isPastAppointment,
    user,
    company,
    companyName,
    scac,
    formattedAppointmentDate,
    formattedAppointmentDateTime,
    appointmentDateTimeHeader,
    refNumLabel,
    currentTimestamp,
    warehouse,
    warehouseAddress,
    appointmentNotes,
    appointmentTags,
    dock,
    loadType,
    statusTimeline,
    arrivalTime,
    formattedArrivalTime,
    completedTime,
    formattedCompletedTime,
    noShow,
    timezone,
    arrivalTimeDiff,
    completedTimeDiff,
    canCopy,
    isStatusMatch,
    formatDateTime,
    getAbbreviatedTimezoneName,
    getAppointmentInterval,
    confirmCancel,
    handleApptIdURLQuery,
    fetchAndOpenAppt,
    getCustomFieldValue,
    copyApptId,
    getWarehouseAddress,
    getUserName,
    setCustomFieldsRows,
    formatPhoneNumber,
    showCustomField,
    getRowVal,
    getAppointmentDateTimeHeader,
    getFullAppointmentTimezone,
    cancelAppointment
  };
}
