<template>
  <div>
    <div>Forms required for the drivers arrival and departure..</div>
    <div class="disclaimer">
      Customize your forms at the warehouse
      <router-link
        :to="{
          name: 'warehouses.details.forms',
          params: { warehouseId: appointment.dock.warehouseId }
        }">
        Fields and Forms
      </router-link>
      section.
    </div>
    <custom-forms-data-panels
      class="mt-4"
      :editable="false"
      :object-ids="{
        appointmentId: appointment.id,
        assetvisitId: appointment.assetVisit?.id
      }"
      :appointment="appointment"
      :triggers="staticTriggers"
      :warehouse="appointment.warehouse"
      :timezone="appointment.warehouse.timezone"
      :military-time-enabled="$isMilitaryTimeEnabled(appointment.warehouse)">
      <template #additional-disclaimer="{ trigger, lastChangedDateTimesInTimezone }">
        <span v-if="!lastChangedDateTimesInTimezone[trigger?.id]" class="text--color-text-disabled">
          {{ novaCore.CustomFormsFeaturesData[trigger?.feature]?.title?.split(':')[0] }}
          pending
        </span>
      </template>
    </custom-forms-data-panels>

    <custom-forms-data-panels
      :editable="true"
      :object-ids="{
        appointmentId: appointment.id,
        assetvisitId: appointment.assetVisit?.id
      }"
      :appointment="appointment"
      :triggers="editableTriggers"
      :warehouse="appointment.warehouse"
      :timezone="appointment.warehouse.timezone"
      :military-time-enabled="$isMilitaryTimeEnabled(appointment.warehouse)">
      <template #additional-disclaimer="{ trigger, lastChangedDateTimesInTimezone }">
        <span v-if="!lastChangedDateTimesInTimezone[trigger?.id]" class="text--color-text-disabled">
          {{ novaCore.CustomFormsFeaturesData[trigger?.feature]?.title?.split(':')[0] }}
          pending
        </span>
      </template>
    </custom-forms-data-panels>
  </div>
</template>
<script>
import { useNovaCore } from '@/composables';
import { computed } from 'vue';

export default {
  props: {
    appointment: {
      type: Object,
      required: true
    },
    triggers: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const novaCore = useNovaCore();
    const editableTriggerNames = ['check-out'];

    const staticTriggers = computed(() => {
      return props.triggers.filter(trigger => {
        const title = novaCore.CustomFormsFeaturesData[trigger?.feature]?.title;
        return !editableTriggerNames.some(editable => title?.toLowerCase()?.includes(editable));
      });
    });

    const editableTriggers = computed(() => {
      return props.triggers.filter(trigger => {
        return !staticTriggers.value.includes(trigger);
      });
    });

    return {
      novaCore,
      staticTriggers,
      editableTriggers
    };
  }
};
</script>
