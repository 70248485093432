import { UserRole, databaseActions } from '@satellite/../nova/core';
import { useStore } from '@/composables/index';

export default function useSocketPlugin() {
  const store = useStore();

  const actions = databaseActions;

  const entities = [
    'Warehouse',
    'WarehouseGroup',
    'User',
    'Appointment',
    'LoadType',
    'Dock',
    'Org',
    'Trigger',
    'FormField',
    'Field',
    'Flow',
    'CustomFormData',
    'AssetVisit',
    'AssetVisitEvent'
  ];

  const connectionStatuses = {
    CONNECTED: 'connected',
    RECONNECTING: 'reconnecting',
    DISCONNECTED: 'disconnected'
  };

  const userEntities = {
    [UserRole.GOD]: ['Org', 'User'],
    [UserRole.INTERNAL]: ['Org', 'User']
  };

  const getActionDisplayName = action => {
    return actions[action].displayName;
  };

  const getActionPastTense = action => {
    return actions[action].pastTense;
  };

  const storeModulesSupportEvent = event => {
    return (
      store.modules()[`${event.entity}s`] &&
      store.modules()[`${event.entity}s`]['actions']['handleSocketEvent']
    );
  };

  return {
    actions,
    entities,
    connectionStatuses,
    userEntities,
    getActionDisplayName,
    getActionPastTense,
    storeModulesSupportEvent
  };
}
